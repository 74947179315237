body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: inherit;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.grey-field {
    display: block;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #dddddd;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
}

.header-logo {
  display: inline-block;
  max-width: 100%; /* Ensure the images don't exceed the column width */
}

.vertical-align {
  display: flex;
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
}