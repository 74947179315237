:root {
  --white : rgb(255,255,255);
  --cream : rgb(238,237,230);
  --lightgray : rgb(236,236,236);
  --darkgray: rgb(193,193,193);
  --lightblue: rgb(58,132,193);
  --darkblue: rgb(4,59,90);
  --lightorange: rgb(225,153,52);
  --darkorange: rgb(237,115,1);
  --darkgreen: rgb(52,178,51);
  --text: rgb(51,51,51);
}
.row-header{
  margin:0px auto;
  padding:0px auto;
}

.row-content {
  margin:0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height:400px;
}


.footer{
  bottom: 0;
  position: relative;
  background-color: var(--darkgreen);
  color: var(--white);
  margin:0px auto;
}
.jumbotron {
  padding:70px 30px 70px 30px;
  margin:0px auto;
  background: #9575CD ;
  color:floralwhite;
}

address{
  font-size:80%;
  margin:0px;
  color:#0f0f0f;
}

.navbar-dark {
  background-color:var(--darkgreen);
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  h3{
    font-size:20px;
    text-align: center;
  }
}

main {
  background-image: url(assets/images/apple_background.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
    padding-bottom: 48px;
}

@media screen and (max-width: 768px) {
    main {
        padding-bottom: 48px;
    }
}

@media screen and (min-width: 768px) {
    main {
        min-height: 65vh;
    }
}

.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.background-green {
  background-color: var(--darkgreen);
}

.text-green {
  color: var(--darkgreen);
}

.text-black  {
  color: var(--text)
}

.text-dark-blue  {
  color: var(--darkblue)
}

.text-light-blue  {
  color: var(--lightblue)
}

label {
    font-size: medium!important;
}

.navbar-nav{
  margin: 0 0;
}

/* Flash class and keyframe animation */
.img-button:hover{
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}
.flash-it {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
  0% { opacity: 1; }
  50% { opacity: .1; }
  100% { opacity: 1; }
}
@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: .1; }
  100% { opacity: 1; }
}

.shake-it {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.pulse-it{
  -webkit-animation: pulse linear .5s infinite;
  animation: pulse linear .5s infinite;
}
@-webkit-keyframes pulse {
  0% { width:200px; }
  50% { width:340px; }
  100% { width:200px; }
}
@keyframes pulse {
  0% { width:200px; }
  50% { width:340px; }
  100% { width:200px; }
}

.navbar-nav li span:after {
  background-image: url(assets/images/menu-arrow.png);
  vertical-align: middle;
  height: 40px;
  width: 19px;
  display: inline-block;
  content: close-quote;
}

.navbar {
  padding: 0 0;
}

ul {
  margin: 0.75em 0;
  padding: 0 1em;
  list-style: none;
}
.footer-links li:before {
  content: "";
  border-color: transparent #fff;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}
.bottom-banner {
  padding: 20px;
  background-color: #34b233;
  color: white;
}

.footer-links a{
  color: var(--white);
  white-space: nowrap!important;
}

.center-text {
    text-align: center;
}

.logout {
    display: inline-block;
    width: 100%;
    text-align: right;
}

.logout a {
    color: #888888;
}

.user-info {
    vertical-align: middle;
    display: inline-block;
    text-align: right;
    margin-right: 5px;
    height: 25px;
}

.fa-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -15%;
    width: 32px;
    height: 40px;
    padding: 0px;
}

.footer-logo {
    max-width: 200px;
}

.header-logo {
    width: auto;
    max-height: 55px;
}

body {
    overflow: scroll;
    overflow-x: hidden;
}

html {
    background-color: white;
}

.vertical-align {
    vertical-align: middle;
}

.header {
    margin-top: 0 !important;
    padding-top: .5rem !important;
    background-color: white;
}

/* @todo next block should probably become active for small screens only, but it looks nice and clear now */

.nav-link span::after, .nav-link::after {
    content: none !important;
}

.nav-link {
    display: inline-block;
    padding: 15px 20px !important;
}

.nav-link-home {
    display: none;
}

/* @endblock */

@media screen and (max-width: 768px) {
    .col-user-info {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .nav-link-logout {
        display: none !important;
    }
}

.group-header {
    font-weight: bold;
    padding-left: 8px !important;
    left: 0 !important;
    padding-top: 20px;

}

.group-header {
    margin-top: 0 !important;
}

.spacing-top {
    padding-top: 20px;
    color: white;
}

.navbar .nav-item {
    font-size: 15px;
}

.navbar .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8);
}